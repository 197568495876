var table_control = (function () {
    var F = {};

    var UrlMomento
    var htmlContent

    F.setDHTML = function () {
        $(document).ready(function () {

            UrlMomento = $('.table-content').attr("data-url");

            // $('.inboxTable:not(.tableContainer .inboxTable)').tablesorter({});
            $('.editarTable').click(function () {
                var id = $(this).parents("tr").attr("data-id")
                F.Editar(id);
            });

            $('.excluirTable').click(function () {
                htmlContent = $(this).html()
                var id = $(this).parents("tr").attr("data-id")
                $(this).html( ' <i class="fa fa-cog fa-spin"></i> Deletando ');
                F.Excluir(id, $(this), htmlContent);
            });

        });

    };

    F.Excluir = function (id, btn, content) {
         alertify.confirm('Deseja mesmo deletar o item  ' + id)
                .set('onok', function () {
                    $.post(UrlMomento + 'excluir', {id: id}, function (res) {
                        var redir = res.redirect ? res.redirect : false;
                        form_control.validaCadastro(res, redir);
                        btn.html(content);
                    }, 'json');
                })
                .set('oncancel', function () {
                    btn.html(content);
                }); 
    };

    F.Editar = function (id) {
        location.href = UrlMomento + 'editar/' + id


    };

    $(function () {
        F.setDHTML();
    });

    return F;

})();


