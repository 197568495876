var form_control = (function () {
    var F = {};
    var key;
    var tok = $("#val-csrf").attr('data-protect');
    var modal_c = $("#ModCarregar");
    var $body = $("body");

    F.resizeWindow = function () {
        var windowHeight = window.innerHeight;
        var windowWidth = $(window).width();
        var contentHeight = windowHeight - $('#header').height();
        var contentWidth = $('#content').width();
        var contentHeight2 = $('#content').height();

        $('#leftSide').height(contentHeight2);
        $('.closeLeftSide').height(contentHeight);
        $('#mapView').height(contentHeight2);
        $('#wrapper').height(contentHeight2);
    };

    F.loadCidades = function (est, form, idx) {
        var html = '<option value="">Escolha uma cidade</option> ';
        $.post("/carrega-cidade", {Estado: est, '_token': tok}, function (res) {
            $.each(res, function (key, val) {
                var ssSelected = '';
                if (form) {
                    ssSelected = $('#' + form).find(".loadCidadesScript").val() && $('#' + form).find(".loadCidadesScript").val() == key ? "selected" : '';
                } else {
                    ssSelected = $(".loadCidadesScript").val() && $(".loadCidadesScript").val() == key ? "selected" : '';
                }
                html += '<option ' + ssSelected + ' value="' + key + '">' + val + '</option>';
            });

            if (form) {
                $('#' + form).find("select.Cidade").html(html).selectpicker('refresh');
            } else {
                $("select.Cidade").html(html).selectpicker('refresh');
            }

        }, "json");
    };

    F.recarregaCsrf = function () {
        var token = $('#val-csrf').attr('data-protect');
        $('.token').val(token)
    };

    F.spinner = function () {

        $(document).on({
            ajaxStart: function () {
                $body.addClass("loading");
            },
            ajaxStop: function () {
                $body.removeClass("loading");
            },
            change: function () {
                $body.removeClass("loading");
            }
        });
    };

    F.setDHTML = function () {

        F.spinner();
        F.recarregaCsrf();

        $(document).ready(function () {

            moment.locale("pt-BR");

            if ($(".loadCidadesScript").val()) {
                $.post("/carrega-cidade", {Cidade: $(".loadCidadesScript").val(), '_token': tok}, function (res) {
                    $(".Estado").val(res.val).selectpicker('refresh');
                    var form = $(this).parent().parents('.formSend').attr('id');
                    if (form)
                        F.loadCidades(res.val, form);
                    else
                        F.loadCidades(res.val);
                }, "json");
            }

            $('span.close').click(function () {
                if ($(this).find('i').hasClass('fa-close')) {
                    $('.busca').fadeOut();
                    $(this).find('i').removeClass('fa-close');
                    $(this).find('i').addClass('fa-plus');
                    F.resizeWindow();
                } else {
                    $('.busca').fadeIn();
                    $(this).find('i').addClass('fa-close');
                    $(this).find('i').removeClass('fa-plus');
                    F.resizeWindow();
                }

            });

            alertify.defaults.theme.ok = "btn btn-primary";
            alertify.defaults.theme.cancel = "btn btn-danger";
            alertify.defaults.theme.input = "form-control";
            alertify.defaults.glossary.title = "Mensagem";


            $(".Regiao").change(function () {
                let regiao = $(this).val();
                $.post("/carrega-estado", {regiao: regiao, '_token': tok}, function (estados) {
                    estados = JSON.parse(estados);

                    let html = '<option value="">Escolha uma cidade</option> ';
                    for(let estado in estados) {
                        html += '<option  value="' + estado + '">' + estados[estado] + '</option>';
                    }
                    $("select.Estado").html(html).selectpicker('refresh');
                });
            });
            $(".Estado").change(function () {
                var form = $(this).parent().parents('.formSend').attr('id');
                if (form)
                    F.loadCidades($(this).val(), form);
                else
                    F.loadCidades($(this).val());
            });

            $('#InputSearch').submit(function (evt) {
                $('.limitePagination2').val($('.limitePagination').val());
            });

            $('select').not('.limitePagination').data('live-search', "true").selectpicker({
                title: "SELECIONE UMA OPÇÃO"
            });

            $('.decimal').maskMoney({
                allowNegative: false,
                thousands: '',
                decimal: '.',
                precision: 2,
                affixesStay: false
            });

            $('.multi-select').multiSelect();
            $('.multi-selectc').chosen();
            $(".periodo").mask("99/9999");
            $('.money').maskMoney({
                prefix: 'R$ ',
                allowNegative: false,
                thousands: '',
                decimal: '.',
                affixesStay: false
            });
            $(".datepick")
                .datepicker({
                    changeMonth: true,
                    changeYear: true,
                    yearRange: 'c-80:c+20'
                }, $.datepicker.regional['pt-BR'])
                .mask("99/99/9999");
            $(".timepick").timepicker({timeFormat: 'HH:mm', stepMinute: 5});
            $(".maskTime,.timepick").mask("00:00");
            $(".cep-mask").mask("00000-000");
            $(".placamask").mask("SSS-0000");
            $('.cpf').mask('000.000.000-00');
            $('.rg').mask('99.999.999-A');
            $('.cnpj').mask("99.999.999/9999-99");
            $(".timepickMinuto").timepicker({timeFormat: 'HH:mm', hourMax: 3, stepMinute: 15});
            var options =  {
                onKeyPress: function(cep, e, field, options) {
                    var masks = ['(00) 0 0000-0000', '(00) 0000-00009'];
                    var mask = (cep.length>14) ? masks[0] : masks[1];
                    $('.tel').mask(mask, options);
                }};

            $('.tel').mask('(00) 0000-00009', options);
            $('.contrato').mask("00000");
            $('.year').mask("0000");

            $('.form-control').keydown(function (e) {
                var $this = $(this);
                $this.removeClass('alert-danger');
                $this.siblings('.red').fadeOut();
            });

            $('.valid-radio').on('click', 'input[type=radio]', function () {
                var $this = $(this);
                var div = $this.parent('div');
                div.siblings('.red').fadeOut();
            });

            $('.form-control, input[type="file"]').on('change', function () {
                var $this = $(this);
                $this.removeClass('alert-danger');
                $this.siblings('.red').fadeOut();
            });

            $('.only-number').keydown(function (e) {
                // Allow: backspace, delete, tab, escape, enter and .
                if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                        // Allow: Ctrl+A
                    (e.keyCode == 65 && e.ctrlKey === true) ||
                        // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            });

            $('#selectAll').click(function() {
                var checked = $(this).prop('checked');
                $('#inboxTable').find('.selectable:checkbox').prop('checked', checked);
            });

            DoubleScroll(document.querySelector('div.table-overflow'));
        });

        function DoubleScroll(element) {
            if(element) {
                var scrollbar = document.createElement('div');
                scrollbar.id = 'doubleScroll';
                scrollbar.appendChild(document.createElement('div'));
                scrollbar.style.overflow = 'auto';
                scrollbar.style.overflowY = 'hidden';
                scrollbar.firstChild.style.width = element.scrollWidth+'px';
                scrollbar.firstChild.style.paddingTop = '1px';
                scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
                scrollbar.onscroll = function() {
                    element.scrollLeft = scrollbar.scrollLeft;
                };
                element.onscroll = function() {
                    scrollbar.scrollLeft = element.scrollLeft;
                };
                element.parentNode.insertBefore(scrollbar, element);
                window.addEventListener('scroll', function(){
                    if(scrollbar.offsetTop <= window.pageYOffset) {
                        scrollbar.style.position = 'sticky';
                        scrollbar.style.top = '40px';
                    }
                });
            }
        }
        $(".alive-list").on('click', ".action", function () {
            var id = $(this).parent().parents('tr').attr("data-id");
            var id2 = $(this).parent().parents('tr').attr("data-id2");
            var url = $(this).parent().parents('tr').attr("data-url");
            var do_action = $(this).attr("data-action");
            if (do_action == 'excluir') {
                alertify.confirm("Deseja Excluir este Item?", function () {
                    $.post(url + '/' + do_action, {id: id, id2: id2, '_token': tok}, function (res) {
                        form_control.validaCadastro(res);
                    }, 'json');
                });
            } else {
                $.post(url + '/' + do_action, {id: id, id2: id2, '_token': tok}, function (res) {
                    F.validaCadastro(res);
                }, 'json');
            }
        });

        $body.on('click', ".alive-action", function () {
            var o = $($(this).attr('data-modal'));
            F.limpaForm(o);
            F.custonAction($(this));
        });

        $body.on('click', ".alive-command", function () {
            F.custonCommand($(this));
        });

        $(".limitePagination").on('change', function () {
            F.insertParam('limitePagination', $(this).val());
        });

        $body.on('change', ".alive-select", function () {
            F.custonAction($(this));
        });

        $(".editaModal").click(function () {
            var id = $(this).parent().parents('tr').attr("data-id");
            var id2 = $(this).parent().parents('tr').attr("data-id2");
            var url = $(this).parent().parents('tbody').attr("data-url");
            var o = $($(this).attr('data-modal'));
            var ttle = $(this).attr('data-title');

            F.limpaForm(o);

            $.post(url + '/editar', {id: id, id2: id2, '_token': tok}, function (res) {
                $(".title-modal").html(ttle);
                F.validaCadastro(res);
                o.modal({
                    keyboard: false,
                    backdrop: 'static'
                });
                //o.modal('toggle');
            }, 'json');

        });

        $(".clearFilters").click(function (e) {
            e.preventDefault();
            $('form').each(function (index, form) {
                F.limpaForm($(form));
                location.href = location.pathname;
            })
        });

        $(".alive-edit").click(function () {
            var id = $(this).attr("data-id");
            var id2 = $(this).attr("data-id2");
            var url = $(this).attr("data-url");
            var do_action = $(this).attr("data-action");
            var o = $($(this).attr('data-modal'));
            F.limpaForm(o);
            $.post(url + do_action, {id: id, id2: id2, '_token': tok}, function (res) {
                F.validaCadastro(res);
            }, 'json');
        });

        $(".alive-remove").click(function () {
            var id = $(this).attr("data-id");
            var url = $(this).attr("data-url");
            var do_action = $(this).attr("data-action");

            $.post(url + do_action, {id: id, '_token': tok}, function (res) {

            });
        });

        $(".excluirModal").click(function () {
            var id = $(this).parent().parents('tr').attr("data-id");
            var id2 = $(this).parent().parents('tr').attr("data-id2");
            var url = $(this).parent().parents('tbody').attr("data-url");
            alertify.confirm("Deseja Excluir este Item?", function () {
                $.post(url + '/excluir', {id: id, id2: id2, '_token': tok}, function (res) {
                    F.validaCadastro(res);
                }, 'json');
            })
        });

        $('.printAll').click(function (e) {
            e.preventDefault();
            let busca = window.location.search;
            let url = $(this).attr("data-url");
            $.ajax({
                type: "GET",
                url: url + "/imprimir",
                data: busca,
                dataType: "json",
                success: function(data) {
                    if (data.ret === 'ok') {

                        if (data.openWindow) {

                            var mime = data.openWindow['Mime'];
                            var content = data.openWindow['Content'];

                            var newWindow = window.open();
                            newWindow.document.write('<iframe src="data:' + mime + ';base64,' + content + '" style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>');
                            newWindow.document.title = "Arquivo";
                        }
                    }
                    //var obj = jQuery.parseJSON(data); if the dataType is not specified as json uncomment this
                    // do what ever you want with the server response
                },
                error: function(data) {
                    alert('Erro ao imprimir dados');
                    console.log(data);
                }
            });
        });

        $(".print").click(function () {
            var id = $(this).parent().parents('tr').attr("data-id");
            var url = $(this).parent().parents('tbody').attr("data-url");
            $.post(url + '/imprimir', {id: id, '_token': tok}, function (res) {
            }, 'json');
        });


        $body.on('click', '.load-modal', function () {
            var o = $($(this).attr('data-modal'));
            var clear = $(this).attr('data-noclear');
            if (clear != 1) {
                F.limpaForm(o);
                o.find('div[class*="lista-"]').contents().remove();
                o.find('div[class*="grupo-"]').contents().not(':first').remove();
            }

            $(".title-modal").html($(this).attr('data-title'));
            o.modal({
                keyboard: false,
                backdrop: 'static'
            });
            //o.modal('toggle');
        });

        $body.on('submit', '.formSend', function (e) {
            e.preventDefault();
            var btn = $(this).find('.btnSend');
            var btnval = $(this).find('.btnSend').html();
            var form = $(this);
            btn.prop('disabled', true).html("Carregando " + '<i class="fa fa-cog fa-spin"></i>');
            F.enviaForm(form, btn, btnval);
            return false;
        });

    };

    F.custonAction = function (obThis) {
        var val = obThis.val();
        var id = obThis.parent().parents('tr').attr("data-id");
        var id = id && id != "" && id != 'undefined' && id != 'null' && id != null ? id : obThis.attr("data-id");
        var id2 = obThis.parent().parents('tr').attr("data-id2");
        var id2 = id2 && id2 != "" && id2 != 'undefined' && id2 != 'null' && id2 != null ? id2 : obThis.attr("data-id2");
        var url = obThis.parent().parents('tbody').attr("data-url");
        var url = url && url != "" && url != 'undefined' && url != 'null' && url != null ? url : obThis.attr("data-url");
        var classValue = $(obThis.attr("data-class-value")).val();

        var do_action = obThis.attr("data-action");
        var do_ask = obThis.attr("data-ask");
        var do_ask_texto = obThis.attr("data-ask-texto");

        if (do_action == 'excluir') {
            alertify.confirm("Deseja Excluir este Item?", function () {
                $.post(url + '/' + do_action, {
                    id: id,
                    id2: id2,
                    val: val,
                    '_token': tok,
                    classValue: classValue
                }, function (res) {
                    form_control.validaCadastro(res);
                }, 'json');
            });
        } else if (do_ask == '1') {
            alertify.confirm(do_ask_texto, function () {
                $.post(url + '/' + do_action, {
                    id: id,
                    id2: id2,
                    val: val,
                    '_token': tok,
                    classValue: classValue
                }, function (res) {
                    form_control.validaCadastro(res);
                }, 'json');
            });
        } else if (do_ask == '2') {
            alertify.prompt(do_ask_texto, '', function (e, str) {
                $.post(url + '/' + do_action, {
                    id: id,
                    id2: id2,
                    val: val,
                    '_token': tok,
                    input: str,
                    classValue: classValue
                }, function (res) {
                    form_control.validaCadastro(res);
                }, 'json');
            });
        }
        else {
            $.post(url + '/' + do_action, {
                id: id,
                id2: id2,
                val: val,
                '_token': tok,
                classValue: classValue
            }, function (res) {
                F.validaCadastro(res);
            }, 'json');
        }
    };

    F.custonCommand = function (obThis) {
        var val = obThis.val();
        var id = obThis.parent().parents('tr').attr("data-id");
        var id = id && id != "" && id != 'undefined' && id != 'null' && id != null ? id : obThis.attr("data-id");
        var url = obThis.parent().parents('tbody').attr("data-url");
        var url = url && url != "" && url != 'undefined' && url != 'null' && url != null ? url : obThis.attr("data-url");

        var do_action = obThis.attr("data-action");

        window.open(url + '/' + do_action + '?id=' + id, "_blank");
    };

    $(document).on('click', '.btnRemove', function () {
        var that = $(this);
        var dvPreview = that.parent().find("div.dvPreview");

        that.parent().find("input[type='file']").val("");
        dvPreview.html("");

        var img = $('<img />');
        img.attr("width", "40%");
        img.attr("class", "camera");
        img.attr("src", "/images/upload.png");

        var span = $('<span />');
        span.text("Adicionar foto de 300x150.");

        dvPreview.append(img);
        dvPreview.append(span);

    });

    $(document).on('change', '.fileupload', function () {
        if (typeof (FileReader) != "undefined") {
            var that = $(this);
            var dvPreview = that.parent().find("div.dvPreview");
            var load = $("<img />");

            dvPreview.html("");
            load.attr("style", "height:130;width: 200px");
            load.attr('src', '/images/loader_seq.gif');
            dvPreview.append(load);

            var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.jpg|.jpeg|.gif|.png|.bmp)$/;
            $($(this)[0].files).each(function () {
                var file = $(this);

                if (regex.test(file[0].name.toLowerCase())) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        dvPreview.html("");
                        var img = $("<img />");
                        img.attr("style", "height:130;width: 200px");
                        img.attr("src", e.target.result);
                        dvPreview.append(img);
                    }
                    reader.readAsDataURL(file[0]);
                } else {
                    alertify.message(file[0].name + " não é uma imagem válida.");
                    dvPreview.html("");
                    return false;
                }
            });
        } else {
            alert("This browser does not support HTML5 FileReader.");
        }
    });

    $(document).on('click', '.dvPreview', function () {
        var that = $(this);
        var parent_input = that.parent().find('input[type="file"]')[0];
        parent_input.click();
    });

    F.enviaForm = function (form, btn, btnval) {
        var action = form.attr("action");
        var disabled = form.find(":input:disabled").removeAttr('disabled');
        var data = form.serializeArray();
        disabled.attr('disabled', 'disabled');
        var id = form.attr("id");
        var do_ask = form.attr("data-ask");
        var do_ask_texto = form.attr("data-ask-texto");
        var files = form.find(':file')[0];
        var formdata = new FormData();

        if (files) {
            if (files.files[0]) {
                formdata.append(files.name, files.files[0]);
            }
        }

        $.each(data, function (key, field) {
            formdata.append(field.name, field.value);
        });

        if (do_ask == '1') {
            alertify.confirm(do_ask_texto, function () {
                $.post(action, data, function (res) {
                    F.recarregaCsrf();
                    var redir = res.redirect ? res.redirect : false;
                    $(".img-carregar").fadeOut();
                    btn.prop('disabled', false).html(btnval);
                    F.validaCadastro(res, redir, '#' + id);
                    if (res.ret == 'ok') {
                        if (res.text) {
                            $('#' + id).html("<div  class=' alert alert-success'>" + res.text + "</div>");
                        }
                    }
                }, 'json');
            }, function () {
                F.recarregaCsrf();
                $(".img-carregar").fadeOut();
                btn.prop('disabled', false).html(btnval);
            })
        } else {
            $.ajax({
                url: action,
                type: "POST",
                data: formdata,
                processData: false,
                contentType: false,
                success: function (res) {
                    res = JSON.parse(res);
                    F.recarregaCsrf();
                    var redir = res.redirect ? res.redirect : false;
                    $(".img-carregar").fadeOut();
                    btn.prop('disabled', false).html(btnval);
                    F.validaCadastro(res, redir, '#' + id);
                    if (res.ret == 'ok') {
                        if (res.text) {
                            $('#' + id).html("<div  class=' alert alert-success'>" + res.text + "</div>");
                        }
                    }
                },
                error: function (res, textStatus, errorThrown) {
                    btn.prop('disabled', false).html(btnval);
                    alertify.alert('Erro ao processar serviço remoto. Tente novamente.');
                }
            });
        }

    };

    F.validaCadastro = function (res, redir, formId) {


        if (res.warning) {
            if (confirm(res.warning.msg)) {
                $.post(res.warning.url, res.warning.data, function (res2) {
                    form_control.validaCadastro(res2, res.warning.reload);
                }, 'json');
            }
        } else if (res.ret === 'error') {
            $(".red").remove();

            if (res.errorMsg) {
                alertify.alert(res.errorMsg);
            }

            if (res.callBack) {
                eval(res.callBack);
            }

            if (res.callBacks) {
                for (var k in res.callBacks)
                    eval(res.callBacks[k]);
            }

            var count = 0;
            for (var k in res.itens) {
                if (count == 0) {
                    $('#' + res.itens[k].field).focus();
                }
                if (res.itens[k].msgBox) {
                    alertify.alert(res.itens[k].msgBox);
                }

                if ($('.' + res.itens[k].field).prop("type") == 'checkbox' && $('.' + res.itens[k].field).prop("name") == 'concordo') {
                    alertify.alert('Você deve aceitar os termos.')
                } else {
                    if (formId) {
                        var erroAsd = '<div role="alert" class="alert red alert-danger alert-dismissible  ">  <div class="icon"><span class="fa fa-ban"></span></div> <button data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span><span class="sr-only">Fechar</span></button>'
                        erroAsd += '<strong>Aviso </strong> '
                        erroAsd += res.itens[k].msg + ' </div> '
                        $(formId).find('.' + res.itens[k].field).addClass('alert-danger');
                        $(formId).find('.' + res.itens[k].field).after(erroAsd);
                    } else {
                        var erroAsd = '<div role="alert" class="alert red alert-danger alert-dismissible  ">  <div class="icon"><span class="fa fa-ban"></span></div> <button data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span><span class="sr-only">Fechar</span></button>'
                        erroAsd += '<strong>Aviso </strong> '
                        erroAsd += res.itens[k].msg + ' </div> '
                        $('#' + res.itens[k].field).addClass('alert-danger');
                        $('#' + res.itens[k].field).after(erroAsd);
                    }

                }


                count++;
            }
            $('.alert-danger').children().children('input').change(function () {
                var div = $(this).parent().parent('.alert-danger');
                var red = div.parent().find(".red");
                red.fadeOut(200);
                div.removeClass('alert-danger');
            });
            $('.alert-danger').children().children().children('input').change(function () {
                var div = $(this).parent().parent().parent('.alert-danger');
                var red = div.parent().find(".red");
                red.fadeOut(200);
                div.removeClass('alert-danger');
            });
        } else if (res.ret === 'ok') {
            if (res.openWindow) {
                var mime = res.openWindow['Mime'];
                var content = res.openWindow['Content'];
                var name = res.openWindow['Name'];

                var newWindow = window.open();
                newWindow.document.write('<iframe src="data:' + mime + ';base64,' + content + '" style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>');
                newWindow.document.title = name;
            }
            if (res.okMsg) {
                alertify.alert(res.okMsg);
            }
            if (res.callBack) {
                eval(res.callBack);
            }
            if (res.callBacks) {
                for (var k in res.callBacks)
                    eval(res.callBacks[k]);
            }
            if (redir) {
                if (redir === 'reload') {
                    location.reload();
                } else {
                    location.href = redir;
                }
            }
            if (res.redirect) {
                if (res.redirect === 'reload') {
                    location.reload();
                } else {
                    location.href = res.redirect;
                }
            }
            if (res.clearForm) {
                F.limpaForm($(res.clearForm));
            }
            if (res.clearForms) {
                for (var k in res.clearForms) {
                    F.limpaForm($('.' + k));
                }
            }
            if (res.clearFormId) {
                for (var k in res.clearFormId) {
                    F.limpaForm($(res.clearFormId[k]));
                }
            }
            if (res.descelectAll) {
                for (var k in res.descelectAll) {
                    $('.' + k).multiSelect('deselect_all');
                }
            }
            if (res.addHidden) {
                for (var k in res.addHidden) {
                    $('.' + res.addHidden[k]).removeClass('hidden').addClass('hidden');
                }
            }
            if (res.removeHidden) {
                for (var k in res.removeHidden) {
                    $('.' + res.removeHidden[k]).removeClass('hidden');
                }
            }
            if (res.setHtml) {
                for (var k in res.setHtml) {
                    $('.' + k).each(function () {
                        if ($(this).prop('tagName') == "SELECT") {
                            $(this).html(res.setHtml[k]).selectpicker('refresh');
                        } else if ($(this).prop('tagName') != "DIV") {
                            $(this).html(res.setHtml[k]);
                        } else if ($(this).prop('className').split('-')[0] == 'lista') {
                            $(this).html(res.setHtml[k]);
                        } else if ($(this).prop('className').split('-')[0] == 'grupo') {
                            $(this).html(res.setHtml[k]);
                            $("div[class^='grupo-'] select").each(function () {
                                $(this).data('live-search', "true").selectpicker({
                                    title: "SELECIONE UMA OPÇÃO"
                                });
                            });
                        } else {
                            $(this).html(res.setHtml[k]);
                        }

                    });
                }
            }
            if(res.setGlobalData) {

            }
            if (res.setField) {
                for (var k in res.setField) {

                    if (k == "Cidade") {
                        if (res.setField[k]) {
                            var form = res.setField['form'];
                            if (form) {
                                $('#' + form).find('.loadCidadesScript').val(res.setField[k]);
                            } else {
                                $('.loadCidadesScript').val(res.setField[k]);
                            }
                            $.post("/carrega-cidade", {Cidade: res.setField[k], '_token': tok}, function (res) {
                                if (form) {
                                    $('#' + form).find(".Estado").val(res.val).selectpicker('refresh');
                                    F.loadCidades(res.val, form);
                                } else {
                                    $('.Estado').val(res.val).selectpicker('refresh');
                                    F.loadCidades(res.val);
                                }

                            }, "json");
                        }

                    }
                    else {
                        $('.' + k).each(function () {
                            if ($(this).prop("tagName") == 'SELECT' || $(this).prop("tagName") == 'TEXTAREA' || $(this).prop("tagName") == 'INPUT') {

                                if ($(this).prop("tagName") == 'INPUT' && $(this).prop("type") == 'checkbox') {

                                    if (res.setField[k] == 1) {
                                        $(this).prop('checked', true);
                                    } else {
                                        $(this).prop('checked', false);
                                    }
                                } else if ($(this).prop("tagName") == "INPUT" && $(this).prop("type") == "radio") {

                                    $(this).each(function () {
                                        if (res.setField[k] == 1) {
                                            $(this).prop('checked', true);
                                        } else {
                                            $(this).prop('checked', false);
                                        }
                                    });

                                } else {

                                    if ($(this).prop("tagName") == 'SELECT') {
                                        $(this).val(res.setField[k]).selectpicker('refresh');
                                    }
                                    else {
                                        $(this).val(res.setField[k]);
                                    }

                                    if ($(this).hasClass('multi-selectc')) {
                                        $(this).trigger('chosen:updated');
                                    }
                                }
                            } else {
                                if ($(this).prop("tagName") != 'DIV') {
                                    $(this).html(res.setField[k]);
                                }
                            }
                        })
                    }
                }

                setTimeout(function () {
                    $('.money').maskMoney({
                        prefix: 'R$ ',
                        allowNegative: false,
                        thousands: '',
                        decimal: '.',
                        affixesStay: false
                    });
                    $('.decimal').maskMoney({
                        allowNegative: false,
                        thousands: '',
                        decimal: '.',
                        precision: 2,
                        affixesStay: false
                    });
                    $(".datepick").datepicker($.datepicker.regional['pt-BR']).mask("99/99/9999");
                }, 1500)


            }

            if (res.setDataTag) {
                for (var k in res.setDataTag) {
                    var id = document.getElementsByClassName('btnRemove')[0];
                    id.setAttribute('data-id', res.setDataTag[k]);
                }
            }

            if (res.setImage) {
                for (var k in res.setImage) {
                    var dvPreview = $("div.dvPreview");
                    dvPreview.html("");

                    var img = $('<img />');
                    img.attr("style", "height:200px;width: 200px");
                    img.attr("src", res.setImage[k]);
                    dvPreview.append(img);
                }
            }
            if (res.setMultiselect) {
                for (var k in res.setMultiselect) {
                    var days = JSON.parse(res.setMultiselect[k]);
                    $('.selectpicker').selectpicker('val', days);
                }
            }
            if (res.modalToggle) {
                for (var k in res.modalToggle) {
                    if (res.modalToggle[k] == 1) {
                        const $modal = $('.' + k);

                        if ($modal.hasClass('in')) {
                            $modal.modal('toggle');
                        } else {
                            $modal.modal({keyboard: false, backdrop: 'static'});
                        }
                    } else {
                        const $modal = $(res.modalToggle[k]);

                        if ($modal.hasClass('in')) {
                            $modal.modal('toggle');
                        }
                        else {
                            $modal.modal({keyboard: false, backdrop: 'static'});
                        }
                    }

                }
            }

            if (res.loadLista) {
                for (var k in res.loadLista) {
                    $.post(res.loadLista[k].url, res.loadLista[k].data, function (resLista) {
                        if (resLista.ret == 'ok') {
                            $("." + resLista.classe).html(F.loadLista(resLista));
                        }
                    }, 'json');
                }
            }
            if (res.loadUrl) {

                for (var k in res.loadUrl) {
                    $.get(res.loadUrl[k].url, {id: res.loadUrl[k].data.id, _token: tok}, function (res) {
                        if (res.ret == 'ok') {
                            form_control.validaCadastro(res);
                        }
                    }, 'json');
                }
            }
        }
        else {
            alert('Ocorreu um problema na conexão.');
        }
    };

    F.loadLista = function (res) {
        var tr_td = '';
        for (var k in res.itens) {
            tr_td += '<tr data-id="' + k + '" data-url="' + res.url + '"  data-id2="' + res.id2 + '">';
            for (var y in res.itens[k]) {
                tr_td += '<td>' + res.itens[k][y] + '</td>';
            }
            tr_td += '</tr>';
        }
        return tr_td;

    };

    F.limpaForm = function (form) {

        form.find("select").each(function () {
            $(this).val("").selectpicker('refresh');
        });

        form.find(".multi-selectc").each(function () {
            $(this).val('').trigger('chosen:updated');
        });

        form.find(".selectpicker").each(function () {
            $(this).selectpicker('deselectAll');
        });

        form.find("input").each(function () {
            if ($(this).attr("type") != "button"
                && $(this).attr("tag") != "button"
                && $(this).attr("type") != "submit"
                && $(this).attr("type") != "radio"
                && $(this).attr("type") != "checkbox") {
                $(this).val("");
            }
            if ($(this).attr("type") == "checkbox") {
                $(this).prop("checked", false);
            }
        });

        form.find('textarea').each(function () {
            $(this).val("");
        });

        form.find('span.Label').each(function () {
            $(this).html("");
        });

        F.recarregaCsrf();
        F.clearImage();
        //F.reloadTabs(form);
    };

    F.reloadTabs = function (form) {

        $(form.selector + " .nav-tabs").children('li').each(function () {
            $(this).removeClass('active');
        });
        $(form.selector + " .nav-tabs").children('li').first().addClass("active");

        $(form.selector + " .tab-content").children('div').each(function () {
            $(this).removeClass('active');
        });
        $(form.selector + " .tab-content").children('div').first().addClass("active");

    };

    F.clearImage = function () {
        var dvPreview = $("div.dvPreview");

        $("input[type='file']").val("");
        dvPreview.html("");

        var img = $('<img />');
        img.attr("width", "40%");
        img.attr("class", "camera");
        img.attr("src", "/images/upload.png");

        var span = $('<span />');
        span.text("Adicionar foto de 300x150.");

        dvPreview.append(img);
        dvPreview.append(span);
    };

    F.insertParam = function (key, value) {
        key = encodeURI(key);
        value = encodeURI(value);

        var kvp = document.location.search.substr(1).split('&');

        var i = kvp.length;
        var x;
        while (i--) {
            x = kvp[i].split('=');

            if (x[0] == key) {
                x[1] = value;
                kvp[i] = x.join('=');
                break;
            }
        }

        if (i < 0) {
            kvp[kvp.length] = [key, value].join('=');
        }

        //this will reload the page, it's likely better to store this until finished
        document.location.search = kvp.join('&');
    };


    $(function () {
        F.setDHTML();
    });

    return F;

})();
